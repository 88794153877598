<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
            :module_name="page.name"
            :record="record"
            @change="save()"
            @archive="archive()"
            :loading="loading"
            :model="page.model">
          <template v-slot:main_fields>
            <template v-for="field in usual_fields">
              <base-field
                  :isDisabled="!canEdit(record.id || 0)"
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-if="tags_required" :isDisabled="!canEdit(record.id || 0)" v-model="record.tags" :modal_name="page.name"/>
          </template>
          <template v-if="custom_fields.length > 0" v-slot:module_fields>
            <template v-if="record.custom_fields">
              <template v-for="field in custom_fields">
                <base-field
                    :isDisabled="!canEdit(record.id || 0)"
                    :key="field.name"
                    :value="record.custom_fields[field.name]"
                    :field="field"
                    @change="customFieldDataChanged"/>
              </template>
            </template>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-tabs v-model="tab">
              <v-tab>{{ $store.getters.translate("edit") }}</v-tab>
              <v-tab @click="preview()">{{ $store.getters.translate("preview") }}</v-tab>
              <v-tab-item>
                <v-row>
                  <v-col cols="2">
                    <v-card flat>
                      <v-card-text>
                        <div>
                          <v-treeview
                              transition
                              item-key="key"
                              dense
                              :items="template_fields"
                              item-text="label">
                            <template v-slot:label="{ item }">
                              <a @click="insertContent(item.key)">{{ $store.getters.translate(item.label) }}</a>
                            </template>
                          </v-treeview>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-divider vertical/>
                  <v-col>
                    <v-card flat>
                      <v-card-text>
                        <editor
                            ref="rte_main"
                            @preview="preview()"
                            :label="$store.getters.translate('content')"
                            v-model="record.html"
                            :module_fields="template_fields"
                            style="height: 100%"
                            :margins="margins"
                            class="mb-5"/>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-card flat style="height: 100%">
                  <v-card-text style="height: 100%">
                    <v-layout
                        v-if="!preview_url"
                        align-center
                        justify-center
                        column
                        fill-height>
                      <v-flex row align-center>
                        <div class="justify-center">
                          <v-progress-circular :size="100" color="primary" indeterminate/>
                          <transition name="fade">
                            <h3 class="mt-5">{{ $store.getters.translate("loading") }}</h3>
                          </transition>
                        </div>
                      </v-flex>
                    </v-layout>
                    <p v-else align="center" style="height: 100%" class="mt-5">
                      <iframe
                          ref="preview"
                          style="overflow: hidden; height: 1000px; width: 100%"
                          height="100%"
                          width="800px"
                          :src="preview_url"
                          frameborder="0"/>
                    </p>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import Editor from "../templates/editor/Edit";
import helpFunctions from "../../plugins/helpFunctions";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";

export default {
  components: {
    BaseBreadcrumb,
    BaseTabPanel,
    Editor,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("reportsection"),
        name: "reportsections",
        model: "reportsection",
      },
      record: {},
      template_fields: [],
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      loading: false,
      margins: {
        margin_top: 20,
        margin_bottom: 20,
        margin_left: 20,
        margin_right: 20,
      },
      tab: 0,
      preview_url: null,
    };
  },
  created() {
    this.load();
    this.template_fields = helpFunctions.template_fields;
    this.getTemplateFields();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      await this.getFields();
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    getTemplateFields() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/fieldsets")
          .then((response) => {
            this.loading = false;
            response.data.data.forEach(fieldset => {
              this.template_fields.push({
                label: fieldset.name,
                key: fieldset.key,
                children: JSON.parse(fieldset.content)
              });
            });
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    preview() {
      this.save();
      this.loading = true;
      this.record.model = this.page.name;
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/preview", this.record)
          .then((response) => {
            this.loading = false;
            this.preview_url = response.data;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    insertContent(key) {
      this.$refs.rte_main.$refs.text_editor.editor.insertContent("{{" + key.toLowerCase() + "}}");
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    canEdit(id = 0) {
      return this.$can("edit", this.$createEntity(this.page.model, {id}));
    },
  },
};
</script>